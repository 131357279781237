// deno-lint-ignore-file no-explicit-any
export const TailwindGap = [
    0,
    0.5,
    1,
    1.5,
    2,
    2.5,
    3,
    3.5,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    14,
    16,
    20,
    24,
    28,
    32,
    36,
    40,
    44,
    48,
    52,
    56,
    60,
    64,
    72,
    80,
    96,
    'px',
    'reverse',
] as const;

export type TailwindGap = typeof TailwindGap[keyof typeof TailwindGap];

type Gap = TailwindGap | Exclude<string | number, TailwindGap>;

export const GapXClass = (gap: Gap, important?: boolean) =>
    `${important ? '!' : ''}gap-x-${
        Object.values(TailwindGap).includes(gap as any) ? gap : (typeof gap == 'string' ? `[${gap}]` : `[${gap}px]`)
    }`;

export const GapYClass = (gap: Gap, important?: boolean) =>
    `${important ? '!' : ''}gap-y-${
        Object.values(TailwindGap).includes(gap as any) ? gap : (typeof gap == 'string' ? `[${gap}]` : `[${gap}px]`)
    }`;

export const GapClass = (gap: Gap, important?: boolean) =>
    `${important ? '!' : ''}gap-${
        Object.values(TailwindGap).includes(gap as any) ? gap : (typeof gap == 'string' ? `[${gap}]` : `[${gap}px]`)
    }`;

export default Gap;
